import React from 'react'
import { Link } from 'react-router-dom'
import IMAGES from '../../assets/images'

function Header() {
  return (
    <div>
      <div className="h-[80px] flex items-center px-10">
        <div className="flex items-center gap-x-5">
          <img src={IMAGES.LOGO_BLACK} alt="done" className="h-fit w-[150px]" />
          <nav className="hidden font-medium text-black md:flex">
            {[
              { title: 'Features', link: '/' },
              { title: 'Token', link: '/token' },
              { title: 'About us', link: '/about' },
              { title: 'Blogs', link: '/blog' },
              { title: 'Contact', link: '/contact' },
            ].map((item, index) => (
              <div className="px-3 hover:text-[#383838]">
                <Link to={item.link} key={index}>
                  {item.title}
                </Link>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Header
