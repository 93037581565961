import React, { useState, useEffect, useContext } from 'react'
import './App.css'
import './index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from './Components/Footer'
import Routing from './Routes'
import Header from './Components/Header'
import ScrollToTop from './Helpers/ScrollTop'
import { LoadingContext } from './Provider/LoadingProvider'
import { useLocation } from 'react-router-dom'
function App() {
  const { loading, setLoading } = useContext(LoadingContext)
  const { pathname } = useLocation()

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      //   // Wait for two second
      if (pathname === '/') {
        await new Promise((r) => setTimeout(r, 5000))
      } else {
        // await new Promise((r) => setTimeout(r, 2000))
      }
      // Toggle loading state
      setLoading(false)
    }
    loadData()
  }, [pathname])

  if (loading) {
    // return <Loading />
  }
  return (
    <div className="App">
      <ScrollToTop />
      {/* <Header setisHeaderOpen={setisHeaderOpen} isHeaderOpen={isHeaderOpen} /> */}
      <Routing />
      {/* <Footer /> */}
      <a href="https://www.sigmatraffic.com/blog/10-tips-to-reduce-website-bounce-rate-what-s-the-average-bounce-rate?ref=220760">
        {/* website average bounce rate */}
      </a>
    </div>
  )
}

export default App
