import React from 'react'
import { Helmet } from 'react-helmet'

function PrivacyPage() {
  return (
    <>
      <Helmet>
        <title>SortCoder: Privacy Policy </title>
        <meta name="description" content="SortCoder: Privacy Policy" />
      </Helmet>
      <div className="container">
        <div className="py-10">
          <h1 className="m-10 mb-9 mt-0 text-center text-7xl text-white">
            Privacy <span className="text_bg_color">Policy</span>
          </h1>
          <div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Privacy Policy for SortCoder
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                At <strong>SortCoder</strong>, accessible from{' '}
                <strong>
                  <a href="https://www.sortcoder.com">www.sortcoder.com</a>
                </strong>
                , one of our main priorities is the privacy of our visitors.
                This Privacy Policy document contains types of information that
                are collected and recorded by <strong>SortCoder</strong> and how
                we use it.
              </p>
              <p className="text-lg text-primaryGrayLight text-left">
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us through
                email at{' '}
                <strong>
                  <a href="mailto:sortcoder.info@gmail.com">
                    sortcoder.info@gmail.com
                  </a>
                </strong>
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">Log Files</h4>
              <p className="text-lg text-primaryGrayLight text-left">
                <strong>SortCoder</strong> follows a standard procedure of using
                log files. These files log visitors when they visit websites.
                All hosting companies do this and a part of hosting services’
                analytics. The information collected by log files includes
                internet protocol (IP) addresses, browser type, Internet Service
                Provider (ISP), date and time stamp, referring/exit pages, and
                possibly the number of clicks. These are not linked to any
                information that is personally identifiable. The purpose of the
                information is for analyzing trends, administering the site,
                tracking users’ movement on the website, and gathering
                demographic information.
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Cookies {'&'} 3rd Party Advertisements
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                We allow third-party companies to serve ads and/or collect
                certain anonymous information when you visit our website. These
                companies may use non-personally identifiable information (e.g.,
                click stream information, browser type, time and date, the
                subject of advertisements clicked or scrolled over) during your
                visits to this and other websites in order to provide
                advertisements about goods and services likely to be of greater
                interest to you. These companies typically use a cookie or third
                party web beacon to collect this information.
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Children’s Information
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>{' '}
              <p className="text-lg text-primaryGrayLight text-left">
                <strong>SortCoder</strong> does not knowingly collect any
                Personal Identifiable Information from children under the age of
                13. If you think that your child provided this kind of
                information on our website, we strongly encourage you to contact
                us immediately and we will do our best efforts to promptly
                remove such information from our records.
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Online Privacy Policy Only
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in{' '}
                <strong>SortCoder</strong>. This policy is not applicable to any
                information collected offline or via channels other than this
                website.
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">Consent</h4>
              <p className="text-lg text-primaryGrayLight text-left">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its Terms and Conditions.
              </p>
            </div>
          </div>
          {/* <Heading1></Heading1> */}
          <div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Third-party disclosure
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                We do not sell any transfer of your personally identifiable data
                to outside and outside groups and companies..
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                Third-party links : Google Analytics
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                We, along with third-party vendors such as{' '}
                <strong>Google</strong>, use first-party cookies (Google
                Analytics) to collect data and information regarding user
                interactions as they pertain to our website.
              </p>
            </div>
            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                How do we protect your information?
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                Your personal information or data is collected behind secure
                networks and can be accessed only by a limited number of people
                who have special access privileges to such systems, and the
                information needs to be kept confidential and secret. Everything
                is e-encrypted through Secure Socket Layer (SSL) technology.
              </p>
            </div>

            <div>
              <h4 className="text-2xl text-white mb-5 text-left">
                How do we use your data or information?
              </h4>
              <p className="text-lg text-primaryGrayLight text-left">
                We may ask you when you register, purchase certain services,
                fill out the contact form and sign up for our newsletter, surf
                the website, or use certain other site features in the following
                ways :<br></br>The collected Data or Information may be used to:
              </p>
              <p className="text-lg text-primaryGrayLight text-left">
                <ul
                  style={{
                    listStyle: 'disc',
                    paddingLeft: '30px',
                  }}
                >
                  <li>
                    To improve our website and network to provide you with
                    better service and information.
                  </li>
                  <li>Provide, operate, and maintain our website</li>
                  <li>To give us better services by customer support</li>
                  <li>
                    To send emails about your services or other products and
                    benefits.
                  </li>
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPage
