import React, { useState } from 'react'
import { BsArrowDown } from 'react-icons/bs'
import { MdKeyboardArrowDown } from 'react-icons/md'

const ServiceCardData = [
  {
    title: 'NFT Development',
    color1: '#ff5555',
    color2: '#ff8b55',
    url:
      'https://img.icons8.com/external-flat-wichaiwi/512/external-nft-gamefi-flat-wichaiwi-2.png',
  },
  {
    title: 'Smart Contract',
    color1: '#009688',
    color2: '#4caf50',
    // icon: <FaFileContract size={40} className="mx-auto" />,
    url:
      'https://img.icons8.com/external-flaticons-flat-flat-icons/512/external-contract-modelling-agency-flaticons-flat-flat-icons-2.png',
  },
  {
    title: 'Coin & Token Development',
    color1: '#00b3ec',
    color2: '#3734ff',
    // icon: <RiHandCoinFill size={40} className="mx-auto" />,
    url:
      'https://img.icons8.com/external-flat-wichaiwi/512/external-token-gamefi-flat-wichaiwi.png',
  },
  {
    title: 'IDO/ICO Development',
    color1: '#8855ff',
    color2: '#8855ff',
    // icon: <RiHandCoinFill size={40} className="mx-auto" />,
    url: 'https://img.icons8.com/color/512/coin-in-hand.png',
  },
  {
    title: 'Custom Blockchain',
    color1: '#00b3ec',
    color2: '#3734ff',
    // icon: <SiBlockchaindotcom size={40} className="mx-auto" />,
    url:
      'https://img.icons8.com/external-flat-wichaiwi/512/external-blockchain-digital-asset-flat-wichaiwi.png',
  },
  {
    title: 'Exchange Development',
    color1: '#4caf50',
    color2: '#3734ff',
    url: 'https://img.icons8.com/fluency/512/financial-analytics.png',
  },
  {
    title: 'Supply Chain',
    color1: '#e91e63',
    color2: '#55ffd7',
    url: 'https://img.icons8.com/color-glass/512/supply-chain.png',
  },
  {
    title: 'Defi Development',
    color1: '#ff55ee',
    color2: '#ff5722',
    url:
      'https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/512/external-dollar-to-euro-money-exchange-service-forex-exchange-money-shadow-tal-revivo.png',
  },
]
// const Technology = [
//   {
//     title: 'InterPlanetary File System',
//     url: 'https://www.jsmastery.pro/_next/static/media/ipfs.67b52e1f.svg',
//   },
//   {
//     title: 'Metamask',
//     url: metamaskLogo,
//   },
//   {
//     title: 'binance',
//     url: binanceLogo,
//   },
//   {
//     title: 'Github',
//     url: githubLogo,
//   },
//   {
//     title: 'Uniswap',
//     url: uniswap,
//   },
//   {
//     title: 'Hardhat Runner',
//     url:
//       'https://hardhat.org/_next/static/media/hardhat-logo-dark.484eb916.svg',
//   },
//   {
//     title: 'Web3',
//     url: 'https://guideofdapp.com/bM6Elm2zdm-80.svg',
//   },
//   {
//     title: 'ThirdWeb',
//     url:
//       'https://blog.thirdweb.com/content/images/2022/08/Thirdweb-Logo-Transparent-White.png',
//   },
//   {
//     title: 'graphql',
//     url: graphql,
//   },
//   {
//     title: 'nextjs',
//     url: nextjs,
//   },
//   {
//     title: 'node',
//     url: node,
//   },
//   {
//     url:
//       'https://blog.openzeppelin.com/wp-content/uploads/2019/05/logo_OZ_white.png',
//   },
//   {
//     title: 'aws',
//     url: aws,
//   },
// ]
const FAQ = [
  {
    title: 'What is Blockchain and Web3.0?',
    content:
      "Writesonic is the world's best AI writing platform for  creating SEO-optimized content that increases organic traffic to your website on Google. By",
  },
  {
    title: 'What is Initial Coin Offering and Initial Dex Offering?',
    content: '',
  },
  {
    title: 'What is Token,Coin and NFT?',
    content: '',
  },
  {
    title: 'What is Initial Coin Offering?',
    content: '',
  },
  {
    title: 'What is Initial Coin Offering?',
    content: '',
  },
]
const ClientReview = [
  {
    review:
      'The platform is easy to use and navigate. I like that you can generate each section step-by-step with ease. The integration of SEMrush is a big win for Writesonic!',
    img:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png',
    name: 'Bonnie Green',
  },
  {
    review:
      'The platform is easy to use and navigate. I like that you can generate each section step-by-step with ease. The integration of SEMrush is a big win for Writesonic!',
    img:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png',
    name: 'Bonnie Green',
  },
  {
    review:
      'The platform is easy to use and navigate. I like that you can generate each section step-by-step with ease. The integration of SEMrush is a big win for Writesonic!',
    img:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png',
    name: 'Bonnie Green',
  },
]
function BlockChain() {
  return (
    <div>
      <div className="py-10">
        {/* //Hero Section */}
        <div className=" container w-full relative h-screen relative">
          <div className="">
            <h5 className="mb-6">
              <span className="text_bg_color uppercase ">Introduction</span>
            </h5>
          </div>
          <div className="">
            {' '}
            <h1 className="m-10 mb-9 mt-0 text-center text-7xl text-white">
              Blockchain <span className="text_bg_color">Development</span>
            </h1>{' '}
            <p className="mx-auto text-xl text-primaryGray max-w-3xl w-full">
              We are building the future with Dapp and blockchain technologies
              to accelerate the world. Blockchain is our mission to build and
              make the most trusted and fastest growing dapps for startups and
              small organizations.
            </p>
          </div>
          <div className="mt-10 animate-bounce text-white">
            <span>
              {' '}
              <BsArrowDown size={60} className="inline" />
            </span>
          </div>
          {/* <div className="relative mx-auto w-full mt-[100px] max-w-[800px]">
            <img
              src={process.env.PUBLIC_URL + "/Assets/svg/half-circle.svg"}
              alt="circle-01"
              width="100%"
            ></img>
          </div> */}

          {/* //Absolute  */}
          <div className="absolute z-10 top-[20%] left-[-10%] pyramid">
            <img src="https://threejs-journey.com/assets/images/illustration-pyramid.webp"></img>
          </div>
          <div className="absolute z-10 top-[0%] right-[0%] cube">
            <img src="https://threejs-journey.com/assets/images/illustration-cube.webp"></img>
          </div>
          <div className="absolute z-10 top-[50%] right-[40%] ball">
            <img src="https://threejs-journey.com/assets/images/illustration-ball.webp"></img>
          </div>
        </div>
        {/* Services */}
        <div className="bg-primaryDark relative z-10 py-20">
          <div className="container">
            <div className="mb-20 ">
              <h2 className="mb-2 text-5xl text-white">
                Blockchain
                <span className="text_bg_color "> Service</span>
              </h2>
              <p className="mx-auto text-xl text-primaryGray max-w-3xl w-full">
                Services we are offering, to build a better future
              </p>
            </div>
            <div className="mt-16">
              <div className="grid grid-cols-4 gap-4">
                {ServiceCardData?.map((item, key) => (
                  <ServiceCard item={item} key={key} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* //Top leaders */}
        <div className=" py-20">
          <div className=" container  text-start flex items-center">
            <div className="flex-1">
              <h3 className="text-4xl text-white capitalize leading-[1.3]">
                leaders in blockchain <br></br>
                <span className="text_bg_color">solution development</span>
              </h3>
            </div>
            <div className="flex-1 ">
              <p className="text-primaryGray text- text-left">
                We believe that blockchain technology is changing how our world
                works for the better and we want to be part of that change.
              </p>
              <div className="relative mt-5 h-[50px] flex justify-start">
                <span
                  className="h-full inline-flex px-5 rounded-md items-center justify-center text-white font-semibold"
                  style={{
                    backgroundImage: `linear-gradient(280deg, #6148ff, #edb8ff)`,
                  }}
                >
                  Talk to us
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* //How We start */}
        <div className="py-20">
          <div className="container">
            {' '}
            <div className="mt-10 mb-20 ">
              <h3 className="mb-2 text-5xl text-white">
                Our Project Development
                <span className="text_bg_color "> Process</span>
              </h3>
              <p className="mx-auto  text-xl text-primaryGray max-w-3xl w-full">
                With our focused top-notch approach, For your new product will
                be delivered in the highest quality.
              </p>
            </div>
            <div className="flex gap-10">
              <div className="flex-1  flex flex-col gap-16">
                <div className="text-left">
                  <h4 className="text-2xl text-white mb-5">
                    Getting Information for the Project
                  </h4>
                  <p className="text-lg text-primaryGrayLight">
                    Our professionals understand your project's needs and future
                    goals, so they can give you with the ideal solution for your
                    project.
                  </p>
                </div>
                <div className="text-left">
                  <h4 className="text-2xl text-white mb-5">Project Planning</h4>
                  <p className="text-lg text-primaryGrayLight">
                    Our professionals plan and develop project scope, roles, and
                    responsibilities in order to provide high-quality project.
                  </p>
                </div>
                <div className="text-left">
                  <h4 className="text-2xl text-white mb-5">
                    Project Development/Execution
                  </h4>
                  <p className="text-lg text-primaryGrayLight">
                    Our professional developers work on your project in
                    accordance with the proper planning.
                  </p>
                </div>
              </div>
              <div className="flex-1 mx-10">
                <div className="h-full">
                  <img
                    className="h-full object-cover rounded-md max-w-[400px] w-full"
                    alt=""
                    src="https://cdn.dribbble.com/userupload/3864543/file/original-70f284e75cbba6594d0330fa6acc57d9.jpg?compress=1&resize=2048x1229"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //Percentage */}
        <div>
          <div className="container py-20">
            <div className="grid grid-cols-4 gap-5">
              <div>
                <h1 className="text-6xl font-bold text-white mb-8 ">92%</h1>
                <p className="text-sm capitalize text-secondaryGrayLight2">
                  project complete percentage{' '}
                </p>
              </div>{' '}
              <div>
                <h1 className="text-6xl font-bold text-white mb-8 ">25+</h1>
                <p className="text-sm capitalize text-secondaryGrayLight2">
                  Number of completed projects
                </p>
              </div>{' '}
              <div>
                <h1 className="text-6xl font-bold text-white mb-8 ">13+</h1>
                <p className="text-sm capitalize text-secondaryGrayLight2">
                  Super happy Client
                </p>
              </div>{' '}
              <div>
                <h1 className="text-6xl font-bold text-white mb-8">91%</h1>
                <p className="text-sm capitalize text-secondaryGrayLight2">
                  project complete percentage{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* //Seamless Technology */}
        <div>
          {' '}
          <div className="container py-20">
            {' '}
            <div className="mt-10 mb-20 ">
              <h3 className="mb-2 text-5xl text-white capitalize leading-[1.3]">
                Seamless integration with<br></br> latest
                <span className="text_bg_color"> technology</span>
              </h3>
              <p className="mx-auto mt-8 text-xl text-primaryGray max-w-3xl w-full">
                SortCoder use the most up-to-date tools and web 3.0 technology
                for your projects,To deliver outstanding development services
              </p>
            </div>
            <div className="flex justify-center gap-5 flex-wrap">
              {
                // Technology?.map((item, key) => (
                //   <div
                //     key={key}
                //     className="bg-[#161b21] items-center gap-4  font-semibold text-white rounded-lg border border-[#f6f6fd26] inline-flex px-6 py-4"
                //   >
                //     <div class="h-[40px] max-w-[150px] mx-auto">
                //       <img
                //         // width="60px"
                //         className="object-contain h-full"
                //         src={item.url}
                //         alt={item.title}
                //         loading="lazy"
                //       />
                //     </div>
                //     {/* <div>{item.title}</div> */}
                //   </div>
                // ))
              }
            </div>
          </div>
        </div>
        {/* //What Our Client */}
        <div className="py-10">
          {' '}
          <div className="container  bg-[#1319234a] rounded-2xl pt-10 px-16">
            {' '}
            <div className="mt-10 mb-20 ">
              <h3 className="mb-2 text-5xl text-white capitalize leading-[1.3]">
                What our <span className="text_bg_color">client's </span>
                say
              </h3>
              <p className="mx-auto mt-8 text-xl text-primaryGray max-w-3xl w-full">
                See what our clients have to say about us. It is really
                important to us. How excellent or bad we will make it for review
                in order to improve SortCoder.
              </p>
            </div>
            <div className="grid grid-cols-3 gap-5">
              {ClientReview.map((client, key) => (
                <div className="flex  gap-5 flex-wrap" key={key}>
                  <div class="flex flex-col max-w-md w-full  rounded-lg p-8  bg-white border-b border-gray-200   md:border">
                    <div class="mb-4 lg:mb-5 ">
                      <blockquote class="my-4 text-left text-sm text-secondaryGrayLight2">
                        " {client.review} "
                      </blockquote>
                      <div class="flex items-center">
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-yellow-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>First star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-yellow-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Second star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-yellow-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Third star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-yellow-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Fourth star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-gray-300 dark:text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Fifth star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex items-center space-x-3">
                      <img
                        class="rounded-full w-9 h-9"
                        src={client.img}
                        alt="profile picture"
                      />
                      <div class="space-y-0.5 font-medium text-left">
                        <div>{client.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* //Company Culture */}
        <div>
          <div className="container  py-20 ">
            <div className="flex  gap-10">
              <div className="flex-1 text-left pr-5">
                <h3 className="mb-2 text-5xl text-white capitalize leading-[1.3]  items-center">
                  Our company values culture ❤️
                </h3>
                <p className="mx-auto mt-8 text-base text-primaryGray max-w-3xl w-full">
                  See what our clients have to say about us. It is really
                  important to us. How excellent or bad we will make it for
                  review in order to improve SortCoder.
                </p>
              </div>
              <div className="flex-1">
                <div className=" flex items-center h-full">
                  <div className="flex  gap-5 flex-wrap justify-end">
                    <div className="bg-white rounded-full font-bold px-8 py-3 inline-flex items-center gap-2">
                      <span className="text-2xl ">✌️</span>
                      <span>Be sincere</span>
                    </div>
                    <div className="bg-white rounded-full font-bold px-8 py-3 inline-flex items-center gap-2">
                      <span className="text-2xl ">🤝</span>
                      <span>Stronger together</span>
                    </div>
                    <div className="bg-white rounded-full font-bold px-8 py-3 inline-flex items-center gap-2">
                      <span className="text-2xl ">😄</span>
                      <span>Keep it smile</span>
                    </div>
                    <div className="bg-white rounded-full font-bold px-8 py-3 inline-flex items-center gap-2">
                      <span className="text-2xl ">💡</span>
                      <span>Take intellisense risk</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //FAQ */}
        <div className="py-20  ">
          <div className="container  py-10   rounded-[28px]">
            <div className="mt-10 mb-20 text-white ">
              <h3 className="mb-2 text-5xl  ">
                Frequently Asked
                <span className="text_bg_color "> Questions</span>
              </h3>
              <p className="mx-auto  text-xl text-secondaryGrayLight2 max-w-3xl w-full">
                If you have further questions please contact us
              </p>
            </div>
            <div className="max-w-[800px] mx-auto">
              <div>
                {FAQ.map((item, i) => (
                  <>
                    <FAQData key={i} item={item} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* //Start Your PRoject With Sortcoder */}
        <div>
          <div className="container bg-[#1319234a] rounded-2xl py-20 px-16">
            <div className="flex h-[300px] gap-10">
              <div className="flex-1 text-left pr-5">
                <h3 className="mb-2 text-5xl text-white capitalize leading-[1.3]  items-center">
                  Start your project with SortCoder
                </h3>
                <p className="mx-auto mt-8 text-base text-primaryGray max-w-3xl w-full">
                  See what our clients have to say about us. It is really
                  important to us. How excellent or bad we will make it for
                  review in order to improve SortCoder.
                </p>
                <button className="bg-white rounded-md text-black px-5 py-3 mt-8">
                  Connect Us
                </button>
              </div>
              <div className="flex-1">
                <img
                  // src={startProject}
                  className="h-full w-full object-cover rounded-md"
                  alt="img"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const ServiceCard = (props) => {
  return (
    <>
      <div className="cursor-pointer transition delay-150 duration-300 ease-in-out hover:-translate-y-6 relative py-6 shadow  rounded-md ">
        <div className="absolute left-0 right-0 bottom-0 top-0 w-full h-full overflow-hidden">
          <div
            className="mx-auto h-[100px] w-[120px] rounded-[50%] mt-[-20px] blur-[50px]"
            style={{
              backgroundImage: `linear-gradient(45deg,${props.item.color1},${props.item.color2})`,
            }}
          ></div>
        </div>
        <div className="relative z-10 text-white">
          <div className="flex justify-center">
            {props.item.url ? (
              <img
                className="max-h-[60px]"
                src={props.item.url}
                alt="image"
                loading="lazy"
              ></img>
            ) : (
              <div className="max-h-[60px] w-full">{props.item.icon}</div>
            )}
          </div>
          <h3 className="mt-5">{props.item.title}</h3>
        </div>
      </div>
    </>
  )
}

const FAQData = ({ item, key }) => {
  const [isOpen, setIsOpen] = useState(false)
  console.log(item)
  return (
    <>
      <div
        key={key}
        className="text-left border border-[#f6f6fd26] text-white rounded-lg w-full py-4 px-8 mb-5"
      >
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h5 className="text-lg text-semibold">{item.title}</h5>
          <MdKeyboardArrowDown size={20} />
        </div>
        {isOpen && (
          <div className="mt-3 ">
            <p className="text-secondaryGrayLight2 text-base">{item.content}</p>
          </div>
        )}
      </div>
    </>
  )
}
export default BlockChain
