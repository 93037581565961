const FEEDBACK_DATA = [
  {
    content:
      'The team was able to implement in a really short time functionality into my AI application.',
    clientName: 'Doiu Cristian',
    star: 5,
    company: '',
  },
  {
    content:
      'The team was very easy to work with and extremely knowledgeable. We look forward to collaborating again on any future Blockchain and web3 projects. I also recommend the team to everyone for Blockchain and web3 projects.',
    clientName: 'Alex Turner',
    star: 5,
    company: '',
  },
  {
    content:
      'I had a recent bug in my Web3 app, and the development team quickly fixed it, demonstrating expertise in Web3 technology. Their clear communication and preventive measures impressed me. Highly satisfied with their technical skills and dedication; I highly recommend their bug-fixing services in the Web3 domain.',
    clientName: 'Lukas',
    star: 5,
    company: '',
  },
  {
    content:
      'It is always a pleasure to work with the team. We are ready to collaborate with him again in the future. He has good knowledge of full-stack development and other things.',
    clientName: 'David Thompson',
    star: 5,
    company: '',
  },
  {
    content:
      'We had the pleasure of working with a true professional on our project. The team was able to develop an AI application swiftly, showcasing perfect execution and delivering excellent results.',
    clientName: 'Doiu Cristian',
    star: 5,
    company: '',
  },
  {
    content:
      'Working with the team has been a pleasure. They are excellent developers and very knowledgeable. The team demonstrated great patience, ability, and creativity. They managed to develop the website we wanted and much more, especially in implementing crypto mechanics. Grammar has been checked for accuracy.',
    clientName: 'Alex Turner',
    star: 4,
    company: '',
  },
]

export default FEEDBACK_DATA
