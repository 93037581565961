import CONTACT_GRP from './contactGrp.png'
import CONTACT_GRP2 from './contactGrp2.png'
import LOGO from './logo.png'
import BG1 from './bg1.png'
import LOGO_BLACK from './logo-black.png'
const IMAGES = {
  CONTACT_GRP,
  LOGO,
  LOGO_BLACK,
  BG1,
  CONTACT_GRP2,
}

export default IMAGES
