import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
function PageNotFoundPage() {
  return (
    <>
      <Helmet>
        <title>SortCoder: Page Not Found |404</title>
      </Helmet>
      <div className="container">
        <div className="h-[400px] flex items-center justify-center">
          <div>
            <h5 className="text-primaryGray">404</h5>
            <h1 className="m-10 my-9 text-center text-7xl text-white">
              Page Not Found
            </h1>
            <div>
              <Link to="/">
                <button className="bg-white py-3 h-full inline-flex px-5 rounded-md items-center justify-center text-black font-semibold ">
                  Go Home
                </button>{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNotFoundPage
