import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import TeamList from '../Components/TeamCard/TeamList'
import { Helmet } from 'react-helmet'
function AboutPage() {
  return (
    <>
      <Helmet>
        <title>SortCoder: About </title>
      </Helmet>
      <div className="max-w-5xl mx-auto mt-10">
        {/* About */}
        <>
          <div
            style={{
              flex: '1',
              textAlign: 'center',
              padding: '26px 12px',
              fontFamily: 'Inter,sans-serif',
            }}
          >
            <h1
              className="mb-10 text-7xl"
              style={{
                // marginBottom: '2.4rem',
                marginTop: 0,
                textAlign: 'center',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
              }}
            >
              Welcome to SortCoder
            </h1>
            <p className="px-16 font-normal text-black text-md">
              We are building the future with Dapp and blockchain technologies
              to accelerate the world. Blockchain is our mission to build and
              make the most trusted and fastest growing dapps for startups and
              small organizations.
            </p>
            <div
              style={{
                height: '500px',
                padding: '26px 12px',
                margin: '0 auto',
                maxWidth: '840px',
                marginTop: '64px',
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/lqN_DvM_e1M"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </>

        <SectionsWrapper>
          {SectionsData.map((item, key) => (
            <Section item={item} key={key} />
          ))}
          <div style={{ position: 'relative' }}>
            <div
              style={{
                backgroundColor: '#fff',
                width: 'fit-content',
                borderRadius: '5px',
                padding: '20px 45px',
                fontWeight: '540',
                fontSize: '1.02rem',

                boxShadow:
                  '0px 0px 1px rgba(0,0,0,0.04),0px 2px 4px rgba(0,0,0,0.04),0px 5px 11px rgba(0,3,66,0.07);',
              }}
            >
              <Link style={{ color: '#000' }} to="/token">
                Get Token
              </Link>
            </div>
          </div>
        </SectionsWrapper>
        {/* About */}
        <div>
          <TimeLineWrapper>
            <TimeLineBGImage
              Left="-20%"
              Right="auto"
              Top="10%"
              Time="10s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <TimeLineBGImage
              Left="auto"
              Right="-20%"
              Top="-24%"
              Time="16s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <h4>
              Sortcoder <span>Timeline</span>{' '}
            </h4>
            <SortcoderTimeLineWrapper>
              {TimeLineData.map((data, key) => (
                <SortcoderTimeLine
                  key={key}
                  top={data?.top}
                  squarecolor={data.squarecolor}
                  endcolor={data.endcolor}
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <h3>{data.year}</h3>
                  <p>{data.text}</p>
                </SortcoderTimeLine>
              ))}
            </SortcoderTimeLineWrapper>
          </TimeLineWrapper>
        </div>

        {/* Team Section */}
        <TeamSection>
          <h4>
            Sortcoder <span>Team</span>{' '}
          </h4>
          <p>The team that makes it all possible.</p>
          <TeamList />
        </TeamSection>
        {/* //Contact Section */}
        <ContactSection>
          <div style={{ margin: '40px 0', fontSize: '40px', color: 'white' }}>
            Connect with us. Build with us.
          </div>
          <Link
            to="/#contact"
            style={{
              padding: '15px 30px',
              cursor: 'pointer',
              background: '#7248ff',
              fontWeight: 'bold',
              borderRadius: '10px',
              color: 'white',
            }}
          >
            Connect Us
          </Link>
        </ContactSection>
      </div>
    </>
  )
}

const Herowrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 56px 24px;
    max-width: 1208px;
    margin: 0 auto;
    flex-direction: row;
  }
  p {
    font-size: 1.2rem;
    max-width: 48rem;

    margin: 0 auto;
    /* color: #788ca0; */
    font-weight: 100;
  }
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    /* span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    } */
  }
  h3 {
    font-size: 3rem;
  }
  @media screen and (min-width: 1200px) {
    h3 {
      font-size: 78px;
    }
  }
`

const SortcoderTimeLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;

  position: relative;
  h3 {
    margin: 0;
    font-size: 34px;
    font-weight: 500;
  }
  p {
    margin: 0;
    margin-top: 10px;
  }
`
const SectionsWrapper = styled.div`
  max-width: 1028px;
  margin: 0 auto;
  padding: 0 23px;
`
const TimeLineWrapper = styled.div`
  padding: 3rem 0;
  font-size: 4rem;
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
`
const SortcoderTimeLine = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  padding-left: 2rem;
  position: relative;
`

const TimeLineBGImage = styled.img`
  animation: bluranim ${(props) => props.Time} infinite;
  position: absolute;
  left: ${(props) => props.Left};
  top: ${(props) => props.Top};
  right: 0;
  bottom: auto;
  z-index: -2;
  max-width: 60%;
  transform: translate3d(0px, 157.529px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  will-change: transform;
  @media (min-width: 640px) {
    /* right: ${(props) => props.Right}; */
  }
`
const TeamSection = styled(TimeLineWrapper)`
  color: #fff;
`
const ContactSection = styled.div`
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  font-weight: 500;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
  background-color: #1319234a;
  padding: 20px 12px;
  border-radius: 30px;
  @media (min-width: 992px) {
    padding: 70px 72px;
  }
`
const TimeLineData = [
  {
    year: 2019,
    text: 'Sortcoder makes its debut.',
    squarecolor: '#a4f8fe',
    endcolor: '#9f54d5',
  },
  {
    year: 2020,
    text: 'Sortcoder take first project.',
    squarecolor: '#9f54d5',
    endcolor: '#9f54d5',
  },
  {
    year: 2021,
    text: 'Complete 10+ project',
    squarecolor: '#c95c80',
    endcolor: '#9f54d5',
  },
  {
    year: 2022,
    text: 'Came into the blockchain industry',
    top: 4,
    squarecolor: '#e35e45',
    endcolor: '#9f54d5',
  },
]
export default AboutPage

const Section = (props) => {
  const SectionContainer = styled.div`
    text-align: left;
    color: #fff;
    display: grid;
    align-items: center;
    margin-top: 6rem;
    grid-gap: 0 1rem;
    gap: 0 1rem;
    grid-auto-flow: dense;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(8, 1fr);
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0 2rem;
      gap: 0 2rem;
    }

    :nth-child(odd) {
      .sect2 {
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width: 768px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: flex;
          justify-content: center;
        }
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: block;
        }
      }
      @media screen and (min-width: 1200px) {
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 7;
        }
        .sect2 {
          grid-column-start: 7;
          grid-column-end: 12;
          display: block;
        }
      }
    }
    :nth-child(even) {
      .sect2 {
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width: 768px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: block;
        }
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
      }
      @media screen and (min-width: 1200px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 6;
          display: block;
        }
        .sect1 {
          grid-column-start: 6;
          grid-column-end: 12;
        }
      }
    }
  `
  const Paragraph = styled.p`
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; */
    -webkit-box-orient: vertical;
    font-size: 1rem;
    margin-top: 1rem;
    color: #ffffffd1;
    margin-bottom: 4rem;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      margin-top: 1rem;
      color: #ffffffd1;
      margin-bottom: 0;
    }
  `

  const Heading4 = styled.h4`
    line-height: 1.777rem;
    word-spacing: 2px;
    color: #fff;
    font-size: 2.4rem;
    line-height: 1.174849;
    margin: 0;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  `

  return (
    <>
      <SectionContainer data={props.item} data-aos="zoom-out-up">
        <div className="sect1">
          <Heading4>{props.item.title}</Heading4>
          <Paragraph>{props.item.text}</Paragraph>
        </div>
        <div className="sect2">
          <img
            alt="ds"
            src={props.item.img}
            width="100%"
            style={{ maxWidth: '500px' }}
          ></img>
        </div>
      </SectionContainer>
    </>
  )
}

const SectionsData = [
  {
    title: 'Organization',
    text:
      'At SortCoder, we are on the cutting-edge of futuristic technologies like blockchain, metaverse, and cloud computing. Our goal is to connect people, foster communities, and boost businesses through these innovative tools. We pour our hearts into building and creating superior products and services for everyone to enjoy. Join us on our journey to enhance the world through technology.',
    flexDirection: 'reverse',
    img: process.env.PUBLIC_URL + '/Assets/svg/organization.png',
  },
  {
    title: 'Technologies',
    text:
      'We are a company that specializes in developing custom decentralized applications (DApps) using the latest technologies like blockchain and the metaverse. We also have expertise in web3, web application development using frameworks like React, Vue, and Angular, and cloud technology. We help businesses innovate and stay current with new technologies.',
    img: process.env.PUBLIC_URL + '/Assets/svg/technology.png',
  },
  {
    title: 'SortCoder Token',
    text:
      'The SRT token is the token of SortCoder. The SRT token is designed to facilitate and encourage the use of the platform in a way that benefits the community. We will soon arrive with services like Staking, Swap, Liquidity and Farming.',
    img: process.env.PUBLIC_URL + '/Assets/svg/coin02.png',
  },
]
