import WORK1 from './work1.png'
import WORK2 from './work2.png'
import WORK3 from './work3.png'
import WORK4 from './work4.png'
import WORK5 from './work5.png'
import WORK6 from './work4.png'
const WORKS = [
  {
    image: WORK1,
    title: 'Samantha AI',
    date: 'DEC 03,2023',
  },
  {
    image: WORK2,
    title: 'Catapult Protocol',
    date: 'DEC 03,2023',
  },
  {
    image: WORK3,
    title: 'Crohodl Finance',
    date: 'DEC 03,2022',
  },
  {
    image: WORK4,
    title: 'NFT Marketplace',
    date: 'DEC 03,2022',
  },
  {
    image: WORK5,
    title: 'Average Punks NFT',
    date: 'DEC 03,2022',
  },
]

export default WORKS
