import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TokenFaqs } from "../../Data/TokenFaqs";
import "./style.css";
function TokenFAQs() {
  let Data = TokenFaqs;
  console.log(Data);
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "fixed", top: "-50px" }}>
        <img
          src={process.env.PUBLIC_URL + "/Assets/svg/space1.svg"}
          style={{ maxWidth: "800px", width: "100%" }}
          alt="space1"
        ></img>
      </div>
      <Container>
        <Heading1>
          <div>Frequently</div> <div>asked questions</div>
        </Heading1>
        {Data !== undefined &&
          TokenFaqs.map((item, value) => {
            return (
              <TopHeroContainer key={value}>
                <div className="flex-1"></div>
                <div className="flex-1">
                  <Heading4>{item.title}</Heading4>
                  <Paragraph>{item.description[0]}</Paragraph>
                  <h3 style={{ cursor: "pointer" }}>
                    <Link style={{ color: "#fff" }} to={"/faq/" + item.uid}>
                      Learn More
                    </Link>
                  </h3>
                </div>
              </TopHeroContainer>
            );
          })}

        <CardWrapper>
          <CardsContainer>
            <Card
              title="DISCORD"
              subtext="Developer Chat"
              url="https://discord.gg/kx3yrukNcD"
            />
            <Card
              title="TELEGRAM"
              subtext="Community Chat"
              url="https://t.me/sortcoder"
            />
          </CardsContainer>{" "}
        </CardWrapper>
      </Container>
    </div>
  );
}

export default TokenFAQs;

function Card(props) {
  const CardContainerInside = styled.a`
    display: block;
    color: #fff;
    :hover {
      background-color: #131923;
    }
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.4s ease-out;
    cursor: pointer;
    padding: 4rem;
    height: 150px;
    text-align: left;
    background-color: #1319234a;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    div {
      color: #ffffff80;
      font-weight: bold;
      display: block;
    }
    h4 {
      font-size: 2.3rem;
      margin: 0;
      margin-top: 8px;
    }
  `;
  return (
    <>
      <CardContainerInside href={props.url} target="_blank">
        <div>{props.title}</div>
        <h4>{props.subtext}</h4>
      </CardContainerInside>
    </>
  );
}
const Heading1 = styled.h1`
  font-size: 2.02rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  @media screen and (min-width: 786px) {
    display: flex;
    font-size: 4.02rem;
  }
`;

const Heading4 = styled.h4`
  line-height: 1.777rem;
  word-spacing: 2px;
  color: #fff;
  font-size: 2rem;
  line-height: 1.174849;
  span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #faaef2, #00dfd8);
  }
`;

const Container = styled.div`
  padding: 0 10px;
  @media screen and (min-width: 1200px) {
    padding-left: 128px;
    padding-right: 128px;
  }
  color: #fff;
  padding-bottom: 100px;
`;

const TopHeroContainer = styled.div`
  text-align: left;
  margin-right: 1.75rem;
  display: block;
  position: relative;
  padding: 0 20px;
  @media screen and (min-width: 786px) {
    display: flex;
  }
  p {
    margin-top: 0.75rem;
    color: #ffffffd1;
    line-height: 1.5;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  max-width: 90rem;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: 786px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Paragraph = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 20px;
`;
