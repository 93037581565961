import React, { Component } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import {
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramFill,
  RiLinkedinFill,
  RiWhatsappFill,
  RiYoutubeFill,
  RiGithubFill,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import IMAGES from '../assets/images'
class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div
        className="bg-no-repeat"
        style={{
          marginTop: '-82rem',
          paddingTop: '77rem',
          backgroundPosition: '50% 100%',
          backgroundSize: '100% 1300px',
          backgroundImage:
            'url(https://assets-global.website-files.com/6527bc4d19181ea4f4e60b47/6527c379b7730429e44030c1_Bottom%20background.png)',
        }}
      >
        <FooterContainer
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay={500}
          data-aos-offset="300"
          data-aos-duration={1000}
          data-aos-once={true}
        >
          <div className="grid grid-cols-4 md:grid-cols-8">
            <NavContainer className="col-span-2">
              <h3>Learn</h3>
              {NavLinkData[0].links.map((items, value) => (
                <div>
                  {items?.hashRouting ? (
                    <HashLink smooth to={items.url}>
                      {items.title}
                    </HashLink>
                  ) : (
                    <Link to={items.url}>{items.title}</Link>
                  )}
                </div>
              ))}
            </NavContainer>
            <NavContainer className="col-span-2">
              <h3>Services</h3>
              {NavLinkData[1].links.map((items, value) => (
                <div>
                  {items?.hashRouting ? (
                    <HashLink smooth to={items.url}>
                      {items.title}
                    </HashLink>
                  ) : (
                    <Link to={items.url}>{items.title}</Link>
                  )}
                </div>
              ))}
            </NavContainer>
            <NavContainer className="col-span-2">
              <h3>Explore</h3>{' '}
              {NavLinkData[2].links.map((items, value) => (
                <div>
                  {items?.hashRouting ? (
                    <HashLink smooth to={items.url}>
                      {items.title}
                    </HashLink>
                  ) : (
                    <Link to={items.url}>{items.title}</Link>
                  )}
                </div>
              ))}
            </NavContainer>
            <NavContainer className="col-span-2">
              <h3>Company</h3>
              {NavLinkData[3].links.map((items, value) => (
                <div>
                  {items?.hashRouting ? (
                    <HashLink smooth to={items.url}>
                      {items.title}
                    </HashLink>
                  ) : (
                    <Link to={items.url}>{items.title}</Link>
                  )}
                </div>
              ))}
            </NavContainer>
          </div>

          <FooterBottomNavSection>
            <Link to="/" style={{ color: '#fff', position: 'relative' }}>
              <img src={IMAGES.LOGO_BLACK} width="120px" alt="logo"></img>
            </Link>
            <FooterSocialWrapper>
              {SocialMediaData.map((data, key) => (
                <a
                  className="transition-all hover:-translate-y-3 hover:scale-150"
                  key={key}
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'white' }}
                >
                  <FooterSocialItem className="text-black">
                    {data.icon}
                  </FooterSocialItem>
                </a>
              ))}
            </FooterSocialWrapper>
          </FooterBottomNavSection>
          <div>
            <FooterBottomSection className="animate-pulse">
              Copyright &copy;
              {new Date().getFullYear()}&nbsp; Sortcoder. All Rights Reserved
            </FooterBottomSection>
          </div>
        </FooterContainer>
      </div>
    )
  }
}

const FooterContainer = styled.div`
  padding: 20px;
  max-width: 1028px;
  margin: 0 auto;
  color: #000;
  /* background: #0b111b; */
  position: relative;
  z-index: 300;
  @media (min-width: 768px) {
    padding: 48px;
  }
`
const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 2rem;
  gap: 0 2rem;
  max-width: 1028px;
  margin: 0 auto;
  padding: 0 10px;
  border-top: 1px solid #ffffff1f;
  border-bottom: 1px solid #ffffff1f;
  @media (min-width: 992px) {
    padding: 0 50px;
  }
  @media (min-width: 768px) {
    padding: 0 40px;
  }
`

const NavContainer = styled.div`
  text-align: left;
  margin-bottom: 5rem;
  margin-top: 5rem;

  div {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
    a {
      color: #000;
      line-height: 1.65;
    }
  }
`
const FooterBottomNavSection = styled.div`
  display: flex;
  align-items: center;
  max-width: 1028px;
  margin: 0 auto;
  padding: 30px 80px;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
`

const FooterSocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const FooterSocialItem = styled.div`
  margin-right: 16px;
  font-size: 22px;
  transition: color 350ms ease;

  &:hover {
    color: #4a3aff;
    cursor: pointer;
  }
`
const FooterBottomSection = styled.div`
  padding: 32px;
  font-size: small;
  display: flex;
  justify-content: center;
  @media (min-width: 442px) {
    font-size: medium;
  }
`

const NavLinkData = [
  {
    title: 'Learn',
    links: [
      {
        url: '/token',
        title: 'Get Token',
      },
      // {
      //   url: '/roadmap',
      //   title: 'Roadmap',
      // },
      // {
      //   url: '/tokonomics',
      //   title: 'Tokonomics',
      // },
      {
        url: '/faq',
        title: 'FAQ',
      },
    ],
  },
  //Service
  {
    title: 'Service',
    links: [
      {
        url: '/blockchain',
        title: 'Blockchain',
      },
      {
        url: '/web-development',
        title: 'Web Development',
      },
      {
        url: '/design',
        title: 'Web & App Design',
      },
      // {
      //   url: '/cloud-technology',
      //   title: 'Cloud Technology',
      // },
      {
        url: '/python-development',
        title: 'Python Development',
      },
    ],
  },
  {
    title: 'Explore',
    links: [
      {
        url: '/blog',
        title: 'Blogs',
      },
      {
        url: '/token/#community',
        title: 'Community',
        hashRouting: true,
      },
      {
        url: '/projects',
        title: 'Our Projects',
      },
    ],
  },
  //Company
  {
    title: 'Company',
    links: [
      {
        url: '/',
        title: 'Home',
      },
      {
        url: '/about',
        title: 'About',
      },

      // {
      //   url: '/#contactform',
      //   title: 'Contact',
      //   hashRouting: true,
      // },

      {
        url: '/terms',
        title: 'Terms & Conditions',
      },
      {
        url: '/privacy',
        title: 'Privacy & Policy',
      },
    ],
  },
]
const SocialMediaData = [
  {
    url: 'https://www.facebook.com/profile.php?id=100081189125943',
    icon: <RiFacebookFill />,
  },
  {
    url: 'https://twitter.com/sortcoder',
    icon: <RiTwitterFill />,
  },
  {
    url: 'https://www.youtube.com/channel/UClxpXUYmmvmTqpsRrYYa3AA',
    icon: <RiYoutubeFill />,
  },
  {
    url: 'https://instagram.com/sortcoder?igshid=YmMyMTA2M2Y=',
    icon: <RiInstagramFill />,
  },
  {
    url:
      'https://www.linkedin.com/in/sortcoder-internet-of-metaverse-and-blockchain-353520218/',
    icon: <RiLinkedinFill />,
  },
  {
    url: '',
    icon: <RiWhatsappFill />,
  },
  {
    url: 'https://github.com/sortcoder',
    icon: <RiGithubFill />,
  },
]
export default Footer
