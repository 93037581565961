import React from 'react'
import HeroSection, { TrustedBrandsNameSection } from './section/HeroSection'
import ServiceSection from './section/ServiceSection'
import PortfolioSection, {
  TestimonialSection,
} from './section/PortfolioSection'
import FAQSection, { ReadyWorkSection } from './section/FAQSection'
import AboutSection, { ChooseSection } from './section/AboutSection'

function HomePage() {
  return (
    <div>
      <HeroSection />
      <TrustedBrandsNameSection />
      <ServiceSection />
      <PortfolioSection />
      <TestimonialSection />
      <ChooseSection />
      <AboutSection />
      <FAQSection />
      <ReadyWorkSection />
    </div>
  )
}

export default HomePage
