'use client'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { PrismicLink, PrismicProvider } from '@prismicio/react'
import { client } from './Services/prismic'
import App from './App'
import './index.css'
import './style.css'
import LoadingProvider from './Provider/LoadingProvider'

const richTextComponents = {
  heading2: ({ children }) => <h2>{children}</h2>,
  heading3: ({ children }) => <h3>{children}</h3>,
  paragraph: ({ children }) => <p>{children}</p>,
  oList: ({ children }) => <ol>{children}</ol>,
  oListItem: ({ children }) => <li>{children}</li>,
  list: ({ children }) => <ul>{children}</ul>,
  listItem: ({ children }) => <li>{children}</li>,
  preformatted: ({ children }) => (
    <pre>
      <code>{children}</code>
    </pre>
  ),
  strong: ({ children }) => <strong>{children}</strong>,
  hyperlink: ({ children, node }) => (
    <PrismicLink field={node.data}>{children}</PrismicLink>
  ),
}

const apolloClient = new ApolloClient({
  uri: 'https://content.sortcoder.tech/graphql',
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <LoadingProvider>
        {/* <PrismicProvider
          client={client}
          richTextComponents={richTextComponents}
        > */}
        <App />
        {/* </PrismicProvider> */}
      </LoadingProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
)
