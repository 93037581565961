import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "sortcodertech";

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken:
    "MC5ZcTNKd3hBQUFDRUFuS2JK.77-977-977-977-9DjBIay_vv71gWTTvv73vv73vv70dD09WbxRHdz5ZVzkTQu-_vQ0"
});

console.log(client);
