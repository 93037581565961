import React from 'react'
import BlogPage from '../Pages/Blog/BlogPage'
import PostPage from '../Pages/Blog/PostPage'
// import HomePage from '../Pages/HomePage'
import { Routes, Route } from 'react-router-dom'
import AboutPage from '../Pages/AboutPage'
import TokenFAQ from '../Pages/TokenPages/TokenFAQ'
import TokenFAQs from '../Pages/TokenPages/TokenFAQs'
import TokenPage from '../Pages/TokenPages/TokenPage'
import PageNotFoundPage from '../Pages/404/PageNotFoundPage'
import Roadmap from '../Pages/Roadmap'
import PrivacyPage from '../Pages/Terms/PrivacyPage'
import BlockChain from '../Pages/ServicePage/Blockchain/BlockChain'
import ICOPage from '../Pages/ServicePage/Blockchain/ServicesPages/ICOPage'
import WebDevelopment from '../Pages/ServicePage/WebDevlopment/WebDevelopment'
import WordpressPage from '../Pages/ServicePage/WebDevlopment/ServicesPages/WordpressPage'
import WebAppDesign from '../Pages/ServicePage/WebAppDesign/WebAppDesign'
import PythonDevelopment from '../Pages/ServicePage/PythonDevlopment/PythonDevelopment'
import HomePage from '../Pages/Home/HomePage'
import MainLayout from '../layout/MainLayout'
import Layout from '../layout/Layout'
export default function Routing(props) {
  return (
    <>
      <Routes>
        {/* //Header links */}
        <Route element={<MainLayout />}>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/:year/:month/:date/:slug" element={<PostPage />} />
          <Route path="/token" element={<TokenPage />} />
          <Route path="/roadmap" element={<Roadmap />} />
        </Route>
        <Route element={<Layout />}>
          <Route index element={<HomePage />} />

          <Route path="/blog" element={<BlogPage />} />

          <Route path="/privacy" element={<PrivacyPage />} />

          {/* //Faq */}
          <Route path="/faq/" element={<TokenFAQs />} />
          <Route path="/faq/:uid" element={<TokenFAQ />} />
          {/* //Blockchain Service */}
          <Route path="/blockchain" element={<BlockChain />} />
          <Route path="/blockchain/ico" element={<ICOPage />} />
          {/* //Web & App Design Service */}
          <Route path="/design" element={<WebAppDesign />} />
          {/* Python Development */}
          <Route path="/python-development" element={<PythonDevelopment />} />
          {/* //Wordpress Service */}
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route
            path="/web-development/wordpress"
            element={<WordpressPage />}
          />

          <Route path="*" element={<PageNotFoundPage />} />
        </Route>
      </Routes>
    </>
  )
}
