import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'
import IMAGES from '../assets/images'
function Header(props) {
  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: 0,
          // backgroundColor: '#0b111b00',
          backgroundColor: '#00000000',
          zIndex: 1001,
          backdropFilter: 'saturate(100%) blur(5px)',
          boxShadow: 'inset 0 -1px 0 0 hsla(0,0%,100%,0.1)',
        }}
      >
        <HeaderContainer className="transition-all delay-50 hover:bg-[#0b111b70] hover:shadow-hoverShadow">
          <Link to="/" style={{ width: '150px', color: '#fff' }}>
            <img src={IMAGES.LOGO} width="100%" alt="logo"></img>
          </Link>
          <HeaderNavLinkWrapper>
            {/* Header Navigation for Desktop */}
            <HeaderNavLinkContainer>
              <div>
                <NavLink to="/">Home</NavLink>
              </div>

              <div>
                <NavLink to="/token">Token</NavLink>
              </div>

              <div>
                <NavLink to="/about">About</NavLink>
              </div>
              <div>
                <NavLink to="/blog">Blog</NavLink>
              </div>
              {/* <div>
                <HashLink
                  style={{ fontWeight: 'bold' }}
                  smooth
                  to="/#contactform"
                >
                  Contact
                </HashLink>
              </div> */}
            </HeaderNavLinkContainer>
          </HeaderNavLinkWrapper>

          {/* Header Navigation for Mobile */}
          {props.isHeaderOpen ? (
            <HeaderNavLinkMobileContainer>
              <div>
                <NavLink to="/">Home</NavLink>
              </div>
              <div>
                <NavLink to="/about">About</NavLink>
              </div>
              <div>
                <NavLink to="/blog">Blog</NavLink>
              </div>
              <div>
                <NavLink to="/">Contact</NavLink>
              </div>
            </HeaderNavLinkMobileContainer>
          ) : null}

          <div
            className="cursor-pointer hamburger-menu"
            onClick={() => props.setisHeaderOpen(!props.isHeaderOpen)}
          >
            <GiHamburgerMenu color="#fff" size={25} />
          </div>
        </HeaderContainer>
      </div>
    </>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
  color: #fff;
  height: 80px;
  position: relative;
  align-items: center;
  @media (min-width: 992px) {
    justify-content: space-around;
  }
  & {
    @media (min-width: 992px) {
      .hamburger-menu {
        display: none;
      }
    }
  }
  /* background-color: #fff; */
`
const HeaderNavLinkWrapper = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    height: 100%;
    margin: auto 0;
    display: flex;
    align-items: center;
  }
`
const HeaderNavLinkContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  div {
    margin-left: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`
// const NavLink2 = styled.div`
//   text-decoration: none;
//   color: #fff;
//   font-weight: bold;
//   margin-left: 0 !important;
//   & {
//     :hover {
//       transition: color 350ms ease;
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//       background-image: linear-gradient(90deg, #faaef2, #00dfd8);
//     }
//   }
// `
const NavLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  & {
    :hover {
      transition: color 350ms ease;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
`
const HeaderNavLinkMobileContainer = styled.div`
  position: absolute;
  top: 79px;
  text-align: left;
  padding: 0 50px;
  left: 0;
  right: 0;

  /* background: #0b111b; */
  background: #000000;
  border-bottom: 1px solid rgba(120, 140, 160, 0.4);
  div {
    margin: 30px 0;
  }
`

export default Header
