import React from "react";
import styled from "styled-components";
import TeamCard from "./TeamCard";

function TeamList() {
  return (
    <TeamListWrapper>
      {TeamData.map((data, key) => (
        <TeamCard data={data} key={key} />
      ))}
    </TeamListWrapper>
  );
}

const TeamData = [
  {
    title: "Ritvik",
    img: process.env.PUBLIC_URL + "Assets/Teams/ritvik.png",
    designation: "Founder and Full Stack Developer",
  },
  {
    title: "Rohan",
    img: "https://assets.website-files.com/6159b2a6c3fdecab90610326/6165155419dd62ee09dad0c2_29%205.png",
    designation: "Co-Founder and  Blockchain Developer",
  },
  {
    title: "Richa",
    img: process.env.PUBLIC_URL + "Assets/Teams/richa.png",
    designation: "UI/UX and Graphics Designer",
  },

  {
    title: "Sams",
    img: process.env.PUBLIC_URL + "Assets/Teams/sams.png",
    designation: "Frontend Developer",
  },
  {
    title: "Kevin",
    img: process.env.PUBLIC_URL + "Assets/Teams/kevin.png",
    designation: "QA Test Engineer",
  },
  {
    title: "Vihaan",
    img: process.env.PUBLIC_URL + "Assets/Teams/vihaan.png",
    designation: "Frontend Developer",
  },
  {
    title: "Ritvik",
    img: process.env.PUBLIC_URL + "Assets/Teams/ritvik2.png",
    designation: "Cloud Enginner",
  },
  {
    title: "Merrick",
    img: process.env.PUBLIC_URL + "Assets/Teams/merrick.png",
    designation: "CMO(Chief Marketing Officer)",
  },
];
const TeamListWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;

  @media screen and (max-width: 1024px) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  element.style {
  }
  @media screen and (max-width: 479px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
`;
export default TeamList;
