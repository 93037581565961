import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BsArrowDown } from 'react-icons/bs'
import WorkProcess from '../../../Components/Services/WorkProcess'
import ServicesList from '../../../Components/Services/ServicesList'
function PythonDevelopment() {
  return (
    <>
      {' '}
      <Helmet>
        <title>SortCoder: Python Development </title>
        <meta
          name="description"
          content="We develop a static and dynamic website on highly optimized code. We have a professional team of developers and designers specializing in demanding technologies, frameworks and tools."
        ></meta>
        <meta
          name="keywords"
          content="SortCoder, Blockchain, blockchain, Wordpress,MERN Development ,MEAN Developmet ,Node.js"
        ></meta>
      </Helmet>
      <>
        <Herowrapper>
          <div
            style={{
              flex: '1',
              color: '#fff',
              textAlign: 'center',
              padding: '26px 12px',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div>
              <h4>
                <span>INTRODUCTION</span>
              </h4>
              <h1
                style={{
                  marginBottom: '2.4rem',
                  marginTop: 0,
                  textAlign: 'center',
                }}
              >
                Python Development
              </h1>
              <p>
                Get a professional Python developer to work on the projects. We
                provide the highest quality Python development services. Our
                Python developers for making your idea come true.
              </p>
            </div>
            <div
              style={{ marginTop: '30px' }}
              className="flex justify-center animate-bounce"
            >
              <BsArrowDown size={60} />
            </div>

            <div
              style={{
                position: 'relative',
                margin: '0 auto',
                marginTop: '100px',
                maxWidth: '800px',
                width: '100%',
              }}
            >
              <img
                src={process.env.PUBLIC_URL + '/Assets/svg/half-circle.svg'}
                alt="circle-01"
                width="100%"
              ></img>
            </div>
          </div>
        </Herowrapper>
        {/* //Bg Image  */}
        <div>
          <TimeLineWrapper>
            <TimeLineBGImage
              Left="-20%"
              Right="auto"
              Top="10%"
              Time="10s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <TimeLineBGImage
              Left="auto"
              Right="-20%"
              Top="-24%"
              Time="16s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
          </TimeLineWrapper>
        </div>

        {/* // Main Section */}
        <MainContainer>
          <DevlopmentServiceContainer>
            <h2>
              <span>Tools</span> We Used
            </h2>
            <p>
              We use these tools to provide best-in-class services to our
              customers behind a superior customer experience.
            </p>
            <ServiceSectionContainer>
              <ServiceSectionWrapper data-aos="fade-up">
                {ServiceCardData.map((item, key) => (
                  <ServiceCard item={item} key={key} />
                ))}
              </ServiceSectionWrapper>
            </ServiceSectionContainer>
          </DevlopmentServiceContainer>
        </MainContainer>
        <QuoteSection>
          <h4 data-aos="zoom-out">
            <span>Build. </span>
            <span>Grow. </span>
            <span>Serve.</span>
          </h4>
        </QuoteSection>

        {/* // How we work  */}

        <WorkShowContainer>
          <img
            src={process.env.PUBLIC_URL + 'Assets/svg/bg-token.svg'}
            alt="bg-token"
            id="work-show-bg-image"
          ></img>
          <WorkShowWrapper className="pt-[100px]">
            <ServicesList
              title={
                <>
                  <span>Services</span> We Offer
                </>
              }
              data={[
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2caaf7b83c6e1bae92e59_feature-icon-05.svg',
                  heading: 'Custom Web Development',
                  content:
                    'Sortcoder ensures your website is truly yours, built to your liking, and equipped with scalable web solutions.',
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2ca94de2369f4a477a646_feature-icon-01.svg',
                  heading: 'E-commerce Solutions',
                  content:
                    'Sortcoder: Crafting solutions for B2C, D2C, B2B, and marketplaces with our skilled web developers, delivering feature-rich websites',
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2ca9b4ae531679ca03146_feature-icon-02.svg',
                  heading: 'Web Apps Development',
                  content:
                    "Elevate your business with Sortcoder's web apps: powering efficiency, integration, and growth.",
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2cab57b83c6e1bae9325e_feature-icon-06.svg',
                  heading: 'Website Development',
                  content:
                    'Sortcoder has empowered over 50+ websites to dominate their industries with highly customizable, SEO-optimized designs for dynamic content management.',
                },
              ]}
            />
            <WorkProcess
              title={
                <>
                  <span>Process</span> We Follow
                </>
              }
              content="Sortcoder creates custom solutions for quick market success with expert   developers."
            />{' '}
          </WorkShowWrapper>
        </WorkShowContainer>
      </>
    </>
  )
}
const WorkListContainer = styled.div`
  max-width: 1028px;
  margin: 0 auto;
`
const WorkListWrapper = styled.div`
  padding: 75px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  z-index: 1000;
  justify-content: space-around;
  :nth-child(even) {
    /* flex-direction: column-reverse; */
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    :nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`

const WorkImageContainer = styled.div`
  /* max-width: 300px; */
  flex: 1;
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
`
const WorkContentContainer = styled.div`
  color: white;
  flex: 1;
  h5 {
    font-size: 2.3rem;
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    text-align: left;
  }
  p {
    font-size: 1.2rem;
    opacity: 0.54;
    font-weight: 100;
    text-align: left;
  }
`

const Herowrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  @media (min-width: 768px) {
    /* padding: 56px 24px; */
    max-width: 1208px;
    margin: 0 auto;
    flex-direction: row;
    padding-bottom: 0;
  }
  p {
    font-size: 1.2rem;
    max-width: 48rem;
    margin: 0 auto;
    color: #788ca0;
    font-weight: 100;
  }
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;

    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
  h1 {
    font-size: 3rem;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 78px;
    }
  }
`

const TimeLineWrapper = styled.div`
  font-size: 4rem;
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
`

const TimeLineBGImage = styled.img`
  animation: bluranim ${(props) => props.Time} infinite;
  position: absolute;
  left: ${(props) => props.Left};
  top: ${(props) => props.Top};
  right: 0;
  bottom: auto;
  z-index: -2;
  max-width: 60%;
  transform: translate3d(0px, 157.529px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  will-change: transform;
  @media (min-width: 640px) {
    /* right: ${(props) => props.Right}; */
  }
`

const MainContainer = styled.div`
  position: relative;
  /* background: #0b111b; */
  background: #000000;
  padding: 56px 24px;
  /* top: -170px; */
  z-index: 10;
`

const ServiceSectionContainer = styled.div``
const ServiceSectionWrapper = styled.div`
  padding-top: 75px;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`

const WorkShowContainer = styled.div`
  position: relative;
  #work-show-bg-image {
    position: absolute;
    right: 0;
    z-index: 1000;
    opacity: 0.8;
    width: 65%;
  }
`
const WorkShowWrapper = styled.div`
  padding-top: 75px;

  h4 {
    font-size: 2rem;
    margin: 10px 0;
    font-weight: bold;
    color: white;
  }
  @media screen and (min-width: 768px) {
    h4 {
      font-size: 2.5rem;
    }
  }
  span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #faaef2, #00dfd8);
  }
`

const DevlopmentServiceContainer = styled.div`
  h2 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    font-size: 2rem;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
  p {
    font-size: 1.2rem;
    max-width: 48rem;
    margin: 0 auto;
    color: #788ca0;
    font-weight: 100;
  }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 2.5rem;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 4rem;
    }
  }
`

const QuoteSection = styled.div`
  h4 {
    font-size: 2rem;
    margin: 10px 0;
    font-weight: bold;
    color: white;
  }
  @media screen and (min-width: 768px) {
    h4 {
      font-size: 2.5rem;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1028px;
  margin: 0 auto;
  height: 200px;
  text-align: left;
  font-weight: 500;
  text-align: center;
  margin-bottom: 48px;
  color: #fff;
  position: relative;
  background-color: #1319234a;
  background-image: url('https://v1.cosmos.network/img/stars.d8924548.svg');
  padding: 20px 12px;
  border-radius: 30px;
  @media (min-width: 992px) {
    padding: 70px 72px;
  }
`
const ServiceCard = (props) => {
  const Container = styled.div`
    /* width: 100%; */
    padding: 0px 16px 80px;
    cursor: pointer;
    will-change: opacity, transform;
    opacity: 1;
    transform-style: preserve-3d;
    transition-duration: 0.3s;
    transition-delay: 0.05s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    :hover {
      transform: translateY(-1rem);
      transition-duration: 0.3s;
      transition-delay: 0.15s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    /* @media screen and (min-width: 768px) {
      width: 50%;
    } */
    @media screen and (min-width: 1200px) {
      width: 33.33%;
    }
  `
  const Wrapper = styled.div`
    flex-direction: column;
    height: 160px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;

    h3 {
      position: relative;
      z-index: 999;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 210px;

      max-width: 85%;
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      color: white;
      font-size: 17px;
      line-height: 1.5;
      font-weight: 700;
      text-align: center;
    }
  `
  const CardBlurBg = styled.div`
    width: 120px;
    height: 100px;
    margin-top: -20px;
    border-radius: 50%;
    background-image: ${(props) =>
      `linear-gradient(45deg,${props.color1}, ${props.color2})`};
    -webkit-filter: blur(50px);
    filter: blur(50px);
  `

  const CardBlurBgContainer = styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: -10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
  `

  const CardImageContainer = styled.div`
    position: relative;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      width: 70px;
    }
  `
  return (
    <>
      <Container>
        <Wrapper>
          <CardImageContainer>
            <img src={props.item.url} alt="dasghdgaj"></img>
          </CardImageContainer>
          <CardBlurBgContainer>
            <CardBlurBg
              color1={props.item.color1}
              color2={props.item.color2}
            ></CardBlurBg>
          </CardBlurBgContainer>

          <h3>{props.item.title}</h3>
        </Wrapper>
      </Container>
    </>
  )
}
export default PythonDevelopment

const ServiceCardData = [
  {
    title: 'Django',
    color1: '#ff5555',
    color2: '#fd0f82',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/django.svg',
  },
  {
    title: 'Flask',
    color1: '#330000',
    color2: '#FF9A00',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/flask.png',
  },
  {
    title: 'Numpy',
    color1: '#00b3ec',
    color2: '#3734ff',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/numpy.svg',
  },
  {
    title: 'PyCharm',
    color1: '#009688',
    color2: '#4caf50',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/pycharm.svg',
  },
  {
    title: 'Jupyter',
    color1: '#00C4CC',
    color2: '#6420FF',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/jupyter.svg',
  },
  {
    title: 'Keras',
    color1: '#FDAD00',
    color2: '#EA6C00',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/keras.svg',
  },
]

const WorkFlowData = [
  {
    title: 'Web Development',
    text:
      'We provide Python a web development service with excellent development quality. We provide dedicated service and we ensure that you get top class growth from it.',
    url: process.env.PUBLIC_URL + 'Assets/svg/python-webdev.svg',
    dataaos: 'zoom-out-right',
  },
  {
    title: 'Programming Solutions',
    text:
      'Any type of Python programming solutions and explanations are designed with feasibility and functionality in mind. Python developers provide solutions that are appropriate and match your business goals',
    url: process.env.PUBLIC_URL + 'Assets/svg/python-solution.svg',
    dataaos: 'zoom-out-right',
  },
  {
    title: 'Web Application',
    text:
      'We are likely to suffer or liable to suffer from developing web applications with Python. Our full-stack Python developers take on jobs, projects and fix every little bug.',
    url: process.env.PUBLIC_URL + 'Assets/svg/python-webapp.svg',
    dataaos: 'zoom-out-left',
  },
]
