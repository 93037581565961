import { useQuery } from '@apollo/client'
import React from 'react'
import { FeaturedBlog, GetPost } from '../../graphql/query/BlogQuery'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import AdsComponent, { ADS_LAYOUT } from '../../Components/AdsComponets'
import PostCard from '../../Components/Cards/PostCard'
import { RiTwitterXFill } from 'react-icons/ri'
import { MdOutlineLink } from 'react-icons/md'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
function PostPage() {
  const { slug } = useParams()
  const { data: getPost } = useQuery(GetPost, {
    variables: {
      id: slug,
    },
  })
  const { data: featuredBlog } = useQuery(FeaturedBlog, {
    variables: { categoryName: 'featured' },
  })
  var re = new RegExp('content.sortcoder.tech', 'g')

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={getPost?.post?.seo?.canonical?.replace(
            'content.sortcoder.tech',
            'sortcoder.tech',
          )}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={getPost?.post?.seo?.canonical?.title}
        />
        <meta
          property="og:description"
          content={getPost?.post?.seo?.canonical?.opengraphDescription}
        />
        <meta
          property="og:url"
          href={getPost?.post?.seo?.opengraphUrl?.replace(
            'content.sortcoder.tech',
            'sortcoder.tech',
          )}
        />
        <meta
          property="og:site_name"
          content={getPost?.post?.seo?.opengraphSiteName}
        />
        <meta
          property="article:published_time"
          content={getPost?.post?.seo?.opengraphModifiedTime}
        />
        <meta
          property="article:modified_time"
          content={getPost?.post?.seo?.opengraphPublishedTime}
        />
        <meta
          property="og:image"
          content={getPost?.post?.seo?.opengraphImage.sourceUrl}
        />
        <script type="application/ld+json\">
          {getPost?.post?.seo?.schema?.raw}
        </script>
        <meta property="og:image:type" content="image/png" />
        <meta name="author" content={getPost?.post?.author?.node?.firstName} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content={getPost?.post?.author?.node?.firstName}
        />
        <meta name="twitter:label2" content="Est. reading time" />
        <meta
          name="twitter:data2"
          content={`${getPost?.post?.seo?.readingTime} minutes`}
        />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: getPost?.post?.seo?.fullHead?.replace(re, 'sortcoder.tech'),
        }}
      ></div>
      <div className="pb-20 text-left text-white ">
        {/* Heading of Blog */}
        <div className="max-w-3xl mx-auto">
          <BlogHeroSection>
            <div className="flex items-center gap-2 my-5 capitalize">
              {_.take(getPost?.post?.tags?.nodes, 5).map((tag) => (
                <span className="p-1 px-2 text-xs font-semibold transition-opacity bg-black border-0 rounded-full cursor-pointer hover:bg-primary ">
                  {tag.name}
                </span>
              ))}
            </div>

            <h1
              className="text-6xl"
              style={{
                fontFamily: 'Inter,sans-serif',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
              }}
            >
              {getPost?.post?.title}
            </h1>
            <div className="mb-10 text-sm text-black">
              <div className="flex mb-2 text-lg">
                <div>By</div>
                <div className="ml-1 font-semibold">Carla Rosa</div>
              </div>
              <div>March 28, 2023</div>
            </div>
            {/* // Share Post */}
            <div className="mb-10 text-sm text-black">
              <div className="flex mb-2 text-lg">
                <div className="font-semibold">Share this post</div>
              </div>
              <div className="flex mt-4 text-lg gap-x-2">
                {[
                  { icon: <MdOutlineLink /> },
                  { icon: <FaLinkedinIn /> },
                  { icon: <FaFacebookF /> },
                  { icon: <RiTwitterXFill /> },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center w-8 h-8 bg-white rounded-full cursor-pointer"
                  >
                    {item.icon}
                  </div>
                ))}
              </div>
            </div>
            {/* <p>{documentsById.data.subtext[0].text}</p> */}
          </BlogHeroSection>
          <div className="relative">
            <div
              className=" h-[80px] absolute w-full"
              style={{
                bottom: '-1px',
              }}
            ></div>
            <div className="px-4">
              <img
                className="flex justify-center w-full max-w-3xl mx-auto rounded-2xl box_shadow"
                src={getPost?.post?.featuredImage?.node?.link}
                alt="sadas"
              />
            </div>
          </div>
        </div>
        {/* Author Section */}

        <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
        {/* //Content  */}
        <div>
          <ContentContainer className="max-w-3xl">
            <p
              dangerouslySetInnerHTML={{
                __html: getPost?.post?.content,
              }}
            ></p>
            <AdsComponent layout={ADS_LAYOUT.article_Layout} />
          </ContentContainer>
        </div>
      </div>
      <div className="">
        <div className="container pb-5">
          <RelatedPosts featuredBlog={featuredBlog} />
        </div>
        <AdsComponent layout={ADS_LAYOUT.horizontal_Layout} />
      </div>
    </>
  )
}

const RelatedPosts = ({ featuredBlog }) => {
  return (
    <div className="px-5 text-left">
      <h2 class="py-10 text-4xl text-white text-left">
        Related <span class="text_bg_color "> Post</span>
      </h2>

      <div className="grid gap-5 pb-5 sm:grid-cols-2 lg:grid-cols-3">
        {featuredBlog?.posts?.nodes?.map((data, key) => (
          <PostCard {...data} key={key} />
        ))}
      </div>
    </div>
  )
}

const BlogHeroSection = styled.div`
  max-width: 1028px;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 2rem;
  padding-top: 50px;

  @media (min-width: 768px) {
    h1 {
      margin: 0;
      margin-bottom: 64px;
      line-height: 1.25em;
      word-spacing: 2px;
      text-shadow: 0 0 80px #c0dbff7a, 0 0 32px #4178ff3d;
    }

    p {
      font-size: 20px;
      line-height: 28px;
    }
  }
`

const ContentContainer = styled.div`
  padding: 10px;
  margin: 0 auto;
  color: white;
  padding: 18px;
  h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  p {
    /* font-size: 1.15rem; */
    color: white;
    margin-bottom: 1.25rem;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.01em;
    strong {
      font-weight: 600;
      border-radius: 8px;
      color: #24335a;
      font-size: 16px;
      line-height: 1.4em;
      padding: 3px;
      /* background-color: #e3e8f4; */
    }
  }
  img {
    border-radius: 10px;
    margin: 18px 0;
    width: 100%;
  }
  ol {
    padding-inline-start: 40px;
  }
  ul {
    padding-inline-start: 40px;
    list-style-type: disc;
  }
  li {
    margin-bottom: 8px;
  }
  div[data-oembed-type='video'] {
    max-height: 530px;
    height: 100vh;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
  a {
    border-bottom: 1px solid #0069ff;
    color: #0069ff;
    text-decoration: none;
    transition: color 0.25s, border-bottom-color 0.25s;
  }
  pre {
    color: #fff;
    border-radius: 16px;
    display: block;
    margin: 1em 0;
    overflow: auto;
    overflow-wrap: normal;
    padding: 1em;
    white-space: normal;
    word-wrap: normal;
    background: #081b4b;
    code {
      line-height: 2.4;
    }
  }
`
export default PostPage
