import React from 'react'

function ServicesList({ data, title }) {
  return (
    <div className="container">
      {/* // Heading  */}
      <div>
        <h2 className="text-6xl text-white mb-8">{title}</h2>
        <p className="text-slate-400">
          Here are the services provided by Sortcoder <br />
          that assist you in creating an eye-catching website.
        </p>
      </div>
      {/* // Services List */}
      <div className=" my-20 grid md:grid-cols-2 gap-[50px]  relative z-[1000]">
        {data.map((service, index) => (
          <Service service={service} key={index} />
        ))}
      </div>
    </div>
  )
}

const Service = ({ service }) => {
  return (
    <>
      <div className="flex transition  flex-col gap-3 items-center p-8 rounded-md hover:bg-[#0d131d] hover:bg-opacity-60 cursor-pointer">
        <img className="w-10 h-10" src={service.icon} />
        <h5 className="text-2xl text-white">{service.heading}</h5>
        <p className="text-base text-slate-400">{service.content}</p>
      </div>
    </>
  )
}
export default ServicesList
