import React, { useState } from 'react'
import Header from '../Components/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
function Layout() {
  const [isHeaderOpen, setisHeaderOpen] = useState(false)
  return (
    <div>
      <Header setisHeaderOpen={setisHeaderOpen} isHeaderOpen={isHeaderOpen} />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout
