import React from 'react'
import { useQuery } from '@apollo/client'
import { FeaturedBlog } from '../../graphql/query/BlogQuery'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import AdsComponent, { ADS_LAYOUT } from '../../Components/AdsComponets'
import PostCard from '../../Components/Cards/PostCard'
function BlogPage() {
  const { data: featuredBlog } = useQuery(FeaturedBlog, {
    variables: { categoryName: 'featured' },
  })
  console.log(featuredBlog)

  const { data: topBlog } = useQuery(FeaturedBlog, {
    variables: { categoryName: 'Top' },
  })
  console.log(topBlog)

  return (
    <div className="relative">
      <div className="container ">
        <div className="absolute right-0 -z-[1]">
          <svg
            width="622"
            height="959"
            viewBox="0 0 622 959"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_5_213)">
              <path
                d="M519 745C687.447 745 824 608.447 824 440C824 271.553 687.447 135 519 135C350.553 135 214 271.553 214 440C214 608.447 350.553 745 519 745Z"
                fill="#962DFF"
                fill-opacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_5_213"
                x="0.279999"
                y="-78.72"
                width="1037.44"
                height="1037.44"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="106.86"
                  result="effect1_foregroundBlur_5_213"
                />
              </filter>
            </defs>
          </svg>
        </div>
        {/* //heading */}
        <div className="px-5">
          <div className="py-[80px]">
            <h1 class="m-10 text-7xl text-left text-white">
              The <span class="text_bg_color">Blog</span>
            </h1>
          </div>
          <div>
            <div className="gap-10 md:flex">
              <div className="md:max-w-[50%] w-full">
                {topBlog?.posts?.nodes[0] && (
                  <TopPost {...topBlog?.posts?.nodes[0]} />
                )}
                {topBlog?.posts?.nodes[1] && (
                  <TopPost {...topBlog?.posts?.nodes[1]} />
                )}
                <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
              </div>
              <div className="md:max-w-[50%] w-full">
                <div className="text-left">
                  <h4 class="text-2xl text-white ">Feature Blog</h4>
                </div>
                {featuredBlog?.posts?.nodes?.map((data, key) =>
                  key === 2 ? (
                    <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
                  ) : (
                    <BlogSideCard {...data} key={key} />
                  ),
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="my-20">
              <h2 class="mb-2 text-6xl text-white text-left">
                Latest <span class="text_bg_color "> Blog</span>
              </h2>
            </div>
            <div className="pb-10">
              <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {featuredBlog?.posts?.nodes?.map((data, key) => (
                  <PostCard {...data} key={key} />
                ))}
              </div>
            </div>
            <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
          </div>
        </div>
      </div>
    </div>
  )
}

const BlogSideCard = ({ tags, date, title, excerpt, featuredImage, uri }) => {
  return (
    <>
      <div className="mt-6">
        <Link to={uri}>
          <div className="">
            <div className="flex gap-3">
              <div className="max-w-[60%] w-full">
                <div className="flex flex-wrap gap-2 mb-3 capitalize">
                  {_.take(tags?.nodes, 2).map((tag) => (
                    <span className="text-sm font-bold text_bg_color2">
                      #{tag.name}
                    </span>
                  ))}
                </div>
                <div className="text-left">
                  <h6 className="mb-2 text-white ">{title}</h6>
                  <div
                    className="mb-2 text-sm three-line feature-blog"
                    dangerouslySetInnerHTML={{ __html: excerpt }}
                  ></div>
                </div>
              </div>

              <div className="max-w-[40%] w-full flex  ">
                <img
                  src={featuredImage?.node?.link}
                  className="object-cover rounded-md"
                  alt=""
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-semibold text-left text-white ">
                {/* January 13, 2023 */}
                {moment(date).format('LL')}
              </p>
            </div>
          </div>
        </Link>
        <div className="py-3 mx-5 border-b border-[#ffffff1c]"></div>
      </div>
    </>
  )
}

const TopPost = ({ title, excerpt, featuredImage, uri }) => {
  return (
    <>
      <Link to={uri}>
        <div>
          <div>
            <img
              src={featuredImage?.node?.link}
              className="rounded-md"
              alt=""
            />
          </div>
          <div>
            <div className="flex my-5">
              <span className="text-sm font-semibold text_bg_color2">
                Sortcoder
              </span>
            </div>
            <div className="text-left">
              <h3 className="text-3xl text-white ">{title}</h3>
              <p
                className="my-5 text-base three-line top-blog "
                dangerouslySetInnerHTML={{ __html: excerpt }}
              ></p>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}
export default BlogPage
