import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BsArrowDown } from 'react-icons/bs'
import ServicesList from '../../../Components/Services/ServicesList'
import WorkProcess from '../../../Components/Services/WorkProcess'
import LatestWorksLine from '../../../Components/LatestWorksLine'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

function WebDevelopment() {
  return (
    <>
      <Helmet>
        <title>SortCoder: Web Development Services </title>
        <meta
          name="description"
          content="We develop a static and dynamic website on highly optimized code. We have a professional team of developers and designers specializing in demanding technologies, frameworks and tools."
        ></meta>
        <meta
          name="keywords"
          content="SortCoder, Blockchain, Wordpress, MERN Development ,MEAN Development ,Node.js,React, Nest.js and React Native"
        ></meta>
      </Helmet>
      <>
        <Herowrapper>
          <div
            style={{
              flex: '1',
              color: '#fff',
              textAlign: 'center',
              padding: '26px 12px',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div>
              <h4>
                <span>INTRODUCTION</span>
              </h4>
              <h1
                style={{
                  marginBottom: '2.4rem',
                  marginTop: 0,
                  textAlign: 'center',
                }}
              >
                Web Development
              </h1>
              <p>
                We are building the future with Dapp and blockchain technologies
                to accelerate the world. Blockchain is our mission to build and
                make the most trusted and fastest growing dapps for startups and
                small organizations.
              </p>
            </div>
            <div className="flex justify-center mt-10 animate-bounce">
              <BsArrowDown size={60} />
            </div>

            <div
              style={{
                position: 'relative',
                margin: '0 auto',
                marginTop: '100px',
                maxWidth: '800px',
                width: '100%',
              }}
            >
              <img
                src={process.env.PUBLIC_URL + '/Assets/svg/half-circle.svg'}
                alt="circle-01"
                width="100%"
              ></img>
            </div>
          </div>
        </Herowrapper>
        {/* //Bg Image  */}
        <div>
          <TimeLineWrapper>
            <TimeLineBGImage
              Left="-20%"
              Right="auto"
              Top="10%"
              Time="10s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <TimeLineBGImage
              Left="auto"
              Right="-20%"
              Top="-24%"
              Time="16s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
          </TimeLineWrapper>
        </div>

        {/* // Main Section */}
        <MainContainer>
          <DevlopmentServiceContainer>
            <h2>
              Web Development <span>Tech</span>
            </h2>
            <p>
              We develop a static and dynamic website on highly optimized code.
              We have a professional team of developers and designers
              specializing in demanding technologies, frameworks and tools.
            </p>
            <ServiceSectionContainer>
              <ServiceSectionWrapper data-aos="fade-up">
                {ServiceCardData.map((item, key) => (
                  <>
                    <ServiceCard item={item} key={key} />{' '}
                  </>
                ))}
              </ServiceSectionWrapper>
            </ServiceSectionContainer>
          </DevlopmentServiceContainer>
        </MainContainer>
        <QuoteSection>
          <h4 data-aos="zoom-out">
            <span>Build. </span>
            <span>Grow. </span>
            <span>Serve.</span>
          </h4>
        </QuoteSection>

        {/* // How we work  */}

        <WorkShowContainer>
          <img
            src={process.env.PUBLIC_URL + 'Assets/svg/bg-token.svg'}
            alt="bg-token"
            id="work-show-bg-image"
          ></img>
          <WorkShowWrapper>
            <ServicesList
              title={
                <>
                  <span>Services</span> We Offer
                </>
              }
              data={[
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2caaf7b83c6e1bae92e59_feature-icon-05.svg',
                  heading: 'Custom Web Development',
                  content:
                    'Sortcoder ensures your website is truly yours, built to your liking, and equipped with scalable web solutions.',
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2ca94de2369f4a477a646_feature-icon-01.svg',
                  heading: 'E-commerce Solutions',
                  content:
                    'Sortcoder: Crafting solutions for B2C, D2C, B2B, and marketplaces with our skilled web developers, delivering feature-rich websites',
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2ca9b4ae531679ca03146_feature-icon-02.svg',
                  heading: 'Web Apps Development',
                  content:
                    "Elevate your business with Sortcoder's web apps: powering efficiency, integration, and growth.",
                },
                {
                  icon:
                    'https://assets.website-files.com/64c36dcc1f3ad420371f2867/64f2cab57b83c6e1bae9325e_feature-icon-06.svg',
                  heading: 'Website Development',
                  content:
                    'Sortcoder has empowered over 50+ websites to dominate their industries with highly customizable, SEO-optimized designs for dynamic content management.',
                },
              ]}
            />

            <div className="">
              <LatestWorksLine />
              <div className="container py-10 my-10">
                <div className="text-center">
                  <h2 className="mb-3 font-bold text-purple-600">Work</h2>
                  <h2 className="mb-3 text-6xl text-white">Our Work</h2>
                  <p className="text-gray-400">
                    Showcasing the Best of My Creative Work
                  </p>
                </div>

                <div className="mt-10">
                  <Swiper
                    loop
                    className="mySwiper"
                    slidesPerView={3}
                    spaceBetween={30}
                  >
                    <SwiperSlide>
                      <div className="bg-white rounded-md h-[300px] w-full flex justify-center">
                        das
                      </div>
                    </SwiperSlide>{' '}
                    <SwiperSlide>
                      <div className="bg-white rounded-md h-[300px] w-full flex justify-center">
                        das
                      </div>
                    </SwiperSlide>{' '}
                    <SwiperSlide>
                      <div className="bg-white rounded-md h-[300px] w-full flex justify-center">
                        das
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>

            <WorkProcess
              title={
                <>
                  <span>Process</span> We Follow
                </>
              }
              content="Sortcoder creates custom solutions for quick market success with expert   developers."
            />
          </WorkShowWrapper>
        </WorkShowContainer>
      </>
    </>
  )
}

const Herowrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  @media (min-width: 768px) {
    /* padding: 56px 24px; */
    max-width: 1208px;
    margin: 0 auto;
    flex-direction: row;
    padding-bottom: 0;
  }
  p {
    font-size: 1.2rem;
    max-width: 48rem;
    margin: 0 auto;
    color: #788ca0;
    font-weight: 100;
  }
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;

    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
  h1 {
    font-size: 3rem;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 78px;
    }
  }
`

const TimeLineWrapper = styled.div`
  font-size: 4rem;
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
`

const TimeLineBGImage = styled.img`
  animation: bluranim ${(props) => props.Time} infinite;
  position: absolute;
  left: ${(props) => props.Left};
  top: ${(props) => props.Top};
  right: 0;
  bottom: auto;
  z-index: -2;
  max-width: 60%;
  transform: translate3d(0px, 157.529px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  will-change: transform;
  @media (min-width: 640px) {
    /* right: ${(props) => props.Right}; */
  }
`

const MainContainer = styled.div`
  position: relative;
  /* background: #0b111b; */
  background: #000000;
  padding: 56px 24px;
  /* top: -170px; */
  z-index: 10;
`

const ServiceSectionContainer = styled.div``
const ServiceSectionWrapper = styled.div`
  padding-top: 75px;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`

const WorkShowContainer = styled.div`
  position: relative;
  #work-show-bg-image {
    position: absolute;
    right: 0;
    z-index: -1;
    opacity: 0.8;
    width: 65%;
  }
`
const WorkShowWrapper = styled.div`
  padding-top: 75px;

  h4 {
    font-size: 2rem;
    margin: 10px 0;
    font-weight: bold;
    color: white;
  }
  @media screen and (min-width: 768px) {
    h4 {
      font-size: 2.5rem;
    }
  }
  span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #faaef2, #00dfd8);
  }
`

const DevlopmentServiceContainer = styled.div`
  h2 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    font-size: 2rem;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
  p {
    font-size: 1.2rem;
    max-width: 48rem;
    margin: 0 auto;
    color: #788ca0;
    font-weight: 100;
  }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 2.5rem;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 4rem;
    }
  }
`

const QuoteSection = styled.div`
  h4 {
    font-size: 2rem;
    margin: 10px 0;
    font-weight: bold;
    color: white;
  }
  @media screen and (min-width: 768px) {
    h4 {
      font-size: 2.5rem;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1028px;
  margin: 0 auto;
  height: 200px;
  text-align: left;
  font-weight: 500;
  text-align: center;
  margin-bottom: 48px;
  color: #fff;
  position: relative;
  background-color: #1319234a;
  background-image: url('https://v1.cosmos.network/img/stars.d8924548.svg');
  padding: 20px 12px;
  border-radius: 30px;
  @media (min-width: 992px) {
    padding: 70px 72px;
  }
`
const ServiceCard = (props) => {
  const Container = styled.div`
    /* width: 100%; */
    padding: 0px 16px 80px;
    cursor: pointer;
    will-change: opacity, transform;
    opacity: 1;
    transform-style: preserve-3d;
    transition-duration: 0.3s;
    transition-delay: 0.05s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    :hover {
      transform: translateY(-1rem);
      transition-duration: 0.3s;
      transition-delay: 0.15s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    /* @media screen and (min-width: 768px) {
      width: 50%;
    } */
    @media screen and (min-width: 1200px) {
      width: 33.33%;
    }
  `
  const Wrapper = styled.div`
    flex-direction: column;
    height: 160px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;

    h3 {
      position: relative;
      z-index: 999;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 210px;

      max-width: 85%;
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      color: white;
      font-size: 17px;
      line-height: 1.5;
      font-weight: 700;
      text-align: center;
    }
  `

  const CardBlurBg = styled.div`
    width: 120px;
    height: 100px;
    margin-top: -20px;
    border-radius: 50%;
    background-image: ${(props) =>
      `linear-gradient(45deg,${props.color1}, ${props.color2})`};
    -webkit-filter: blur(50px);
    filter: blur(50px);
  `

  const CardBlurBgContainer = styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: -10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
  `

  const CardImageContainer = styled.div`
    position: relative;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      width: 70px;
    }
  `
  return (
    <>
      <Container>
        <Wrapper>
          <CardImageContainer>
            <img src={props.item.url} alt="dasghdgaj"></img>
          </CardImageContainer>
          <CardBlurBgContainer>
            <CardBlurBg
              color1={props.item.color1}
              color2={props.item.color2}
            ></CardBlurBg>
          </CardBlurBgContainer>

          <h3>{props.item.title}</h3>
        </Wrapper>
      </Container>
    </>
  )
}
export default WebDevelopment

const ServiceCardData = [
  {
    title: 'Wordpress',
    color1: '#ff5555',
    color2: '#fd0f82',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/wordpress.svg',
  },
  {
    title: 'Node.js Development',
    color1: '#009688',
    color2: '#4caf50',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/node-js.svg',
  },
  {
    title: 'MERN Development',
    color1: '#00b3ec',
    color2: '#3734ff',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/react.svg',
  },
  {
    title: 'MEAN Development',
    color1: '#e91e63',
    color2: '#ff5722',
    url: process.env.PUBLIC_URL + '/Assets/svg/services/angular.svg',
  },
]
