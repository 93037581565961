import React from 'react'
import { LuGoal } from 'react-icons/lu'
import { MdOutlineDesignServices, MdSupport } from 'react-icons/md'
import { TbDeviceDesktopCode } from 'react-icons/tb'
import { RiBugLine } from 'react-icons/ri'
function WorkProcess({ title, content }) {
  return (
    <div className="container">
      {/* // Heading  */}
      <div>
        <h2 className="mb-8 text-6xl text-white">{title}</h2>
        <p className="text-slate-400">{content}</p>
      </div>
      {/* // Services List */}
      <div className=" my-20 flex gap-[30px] mb-10 flex-wrap justify-center  relative z-[1000]">
        {[
          {
            title: 'Planning',
            icon: <LuGoal size={26} color="#fff" />,
            content:
              'In the planning phase, define project goals, create a roadmap, and involve key stakeholders.',
          },
          {
            title: 'Design',
            icon: <MdOutlineDesignServices size={26} color="#fff" />,
            content:
              'During the design phase, create visual prototypes, gather user feedback, and refine design concepts.',
          },
          {
            title: 'Development',
            icon: <TbDeviceDesktopCode size={26} color="#fff" />,
            content:
              'In the development phase, write and code the software while adhering to coding standards and best practices.',
          },
          {
            title: 'Quality Assurance',
            icon: <RiBugLine size={26} color="#fff" />,
            content:
              'Quality assurance involves rigorous testing, issue documentation, and prioritization to ensure software quality.',
          },
          {
            title: 'Deployment',
            icon: <MdOutlineDesignServices size={26} color="#fff" />,
            content:
              'Prepare for a seamless product launch, monitor system performance, and ensure data migration and backup procedures.',
          },
          {
            title: 'Support & Maintenance',
            icon: <MdSupport size={26} color="#fff" />,
            content:
              'Provide ongoing assistance and updates, establish a helpdesk, and regularly patch the software to ensure functionality.',
          },
        ].map((service, index) => (
          <ProcessStep
            title={service.title}
            content={service.content}
            index={index + 1}
            icon={service.icon}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

const ProcessStep = ({ index, title, content, icon }) => {
  return (
    <>
      {/* <div className="flex flex-col items-center w-full max-w-sm transition cursor-pointer hover:animate-pulse hover:-translate-y-6">
        <div className="w-[100px]  h-[100px] rounded-md border-2 border-indigo-400 bg-indigo-500 bg-opacity-90">
          <h3 className="flex items-center justify-center h-full text-2xl text-white">
            {index}
          </h3>
        </div>
        <h3 className="mt-5 text-lg font-medium text-white">{title}</h3>
        <p className="text-base text-slate-400">{content}</p>
      </div> */}
      <div className="p-5 bg-[#1319234a]  backdrop-blur-sm  rounded-md bg-clip-padding w-[400px] shadow-lg bg-opacity-10">
        <div className="flex flex-col items-start gap-10">
          <div className="flex flex-col items-start gap-y-3 ">
            <div className="flex items-center justify-center border-2 border-[#8250DF] rounded-full white w-14 h-14">
              <span>{icon}</span>
            </div>
            <h5 className="text-white">{title}</h5>
            <p className="text-base text-left text-gray-300">{content}</p>
          </div>

          <div className="flex justify-end w-full text-3xl font-semibold text-right text-white ">
            <span>{index}</span>
          </div>
        </div>
      </div>
    </>
  )
}
export default WorkProcess
