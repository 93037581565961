import React, { useState } from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer'
import { Outlet } from 'react-router-dom'
function MainLayout() {
  const [isHeaderOpen, setisHeaderOpen] = useState(false)
  return (
    <div
      className="bg-no-repeat"
      style={{
        backgroundSize: '100% 1380px',
        backgroundImage:
          'url(https://assets-global.website-files.com/6527bc4d19181ea4f4e60b47/6527bf1dcd5ef92c30324349_Top%20Background.png)',
      }}
    >
      <Header setisHeaderOpen={setisHeaderOpen} isHeaderOpen={isHeaderOpen} />
      <Outlet />

      <Footer />
    </div>
  )
}
export default MainLayout
